import React, { useEffect } from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import focusConnect from "reactSrc/store/connect/focusConnect";

import routes from "reactSrc/routes/config";
import GeneralHelper from "reactSrc/helpers/GeneralHelper";
import { Hub } from "aws-amplify";
import awsHelper from "reactSrc/helpers/AWSHelper";
import GoogleAnalyticsHelper from "reactSrc/helpers/GoogleAnalyticsHelper";

const Layout = ({ classes, history, setUserObj, signOutUser }) => {
  useEffect(() => {
    awsHelper.configureCognito();
    getCurrentUser();

    Hub.listen("auth", ({ payload: { event, data } }) => {
      if (event === "signIn") {
        setUserAttributes(data);
      }
      if (event === "signOut") {
        signOutUser();
      }
      if (event === "configured") {
        localStorage.setItem("amplify-redirected-from-hosted-ui", false);
      }
    });

    /* start up basic GA tracking */
    GoogleAnalyticsHelper.client.initBasicTracking("UA-189680614-1");
    GoogleAnalyticsHelper.sendPageview("Designer");

    GoogleAnalyticsHelper.startSession();

    document.defaultView.addEventListener("error", (errEvent) => {
      GoogleAnalyticsHelper.sendEvent(
        "Error",
        errEvent.message,
        errEvent.lineno
      );
    });
  }, []);

  const getCurrentUser = async () => {
    const user = await awsHelper.getCurrentUser();
    if (user && typeof user == "object") {
      setUserAttributes(user);
      // TODO: if ( optInToTrackingEverythingIDo === true ) {
      if (user.hasOwnProperty("username") === true) {
        GoogleAnalyticsHelper.setLoggedInUserID( user.username );
      }
      if (user.hasOwnProperty("attributes") === true) {
        GoogleAnalyticsHelper.setUserSub( user.attributes.sub );
      }
      //}
    }
  };

  const setUserAttributes = (data) => {
    data.attributes
      ? setUserObj({
          ...data.attributes,
          ...data.signInUserSession.accessToken.payload,
        })
      : setUserObj({
          ...data.accessToken.payload,
          ...data.signInUserSession.accessToken.payload,
        });
  };

  const getRoutes = () => {
    return [
      ...routes.map((route) => {
        return (
          <Route
            key={GeneralHelper.iterateKey(route)}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        );
      }),
      ...[
        <Route
          key={GeneralHelper.getUniqueKey()}
          render={() => <Redirect to="/404" />}
        />,
      ],
    ];
  };

  return (
    <div id={"layout"} className={classes.body}>
      <BrowserRouter>
        <Switch>{getRoutes()}</Switch>
      </BrowserRouter>
    </div>
  );
};

export default focusConnect({
  component: Layout,
  styles: (theme) => ({
    body: {
      background: "#E0E0E0",
      display: "flex",
      flexDirection: "column",
      flex: 1,
      overflow: "hidden",
    },
  }),
});
