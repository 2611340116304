
const initialState = {
    user: null
}

function auth(state = initialState, action) {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                user: action.payload
            }
        case 'SIGNOUT_USER':
            return {
                ...state,
                user: null
            }
        default:
            return state;
    }
}

export default auth;
