import { connect } from "react-redux";
// import { enqueueSnackbar } from "reactSrc/store/actions/notificationActions";
// import { toggleLoader }    from "reactSrc/store/actions/loaderActions"
// import { getUserObj }      from "reactSrc/store/actions/authActions";
import { withStyles } from "@material-ui/core/styles";
import { setUserObj, signOutUser } from "reactSrc/store/actions/authActions";

const globalActions = { setUserObj, signOutUser };

function getGlobalState(state) {
  return { user: state.auth.user };
  // return {}
}

function focusConnect({
  component: component = () => ({}),
  mapState: mapState = (state) => ({}),
  mapActions: mapActions = {},
  styles: styles = (theme) => ({}),
}) {
  return connect(
    (state) => ({ ...mapState(state), ...getGlobalState(state) }),
    { ...mapActions, ...globalActions },
    (stateProps, dispatchProps, ownProps) => ({
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
    }),
    { forwardRef: true }
  )(withStyles(styles)(component));
}

export default focusConnect;
