import React from "react";
import PropTypes from "prop-types";

import CircleLoader from "./CircleLoader";
import NextLogo from '@images/NextLogo.png';

// set display name for component
const displayName = "CommonLoader";

// validate component properties
const propTypes = {
    isLoading: PropTypes.bool,
    error: PropTypes.object
};

const LoadingComponent = ({ isLoading, error }) => {
    // Handle the loading state
    if (isLoading) {
        return (
            <div className="row center-align circularProgress">
                <CircleLoader />
            </div>
        );
    }
    // Handle the error state
    else if (error) {
        return (
            <div style={{height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <img src={NextLogo} style={{height:150}}/>
                <br/>
                <br/>
                <div>
                    Sorry, there was a error loading this page.
                </div>
            </div>
        )
    } else {
        return null;
    }
};

LoadingComponent.displayName = displayName;
LoadingComponent.propTypes = propTypes;

export default LoadingComponent;
