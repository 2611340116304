import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';

const styles = theme => ({
    progress: {
        position: 'fixed!important',
        top: '50vh!important',
        left: '50%!important',
        zIndex: 9999
    },
    progressContainer: {
        height: '100%',
        position: 'relative'
    }
});

function CircleLoader(props) {
    const { classes, style } = props;
    return (
        <div className={classes.progressContainer}>
            <CircularProgress className={classes.progress} style={style ? style : {}} />
        </div>
    );
}

CircleLoader.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CircleLoader);
