import Amplify, { Auth, Storage } from "aws-amplify";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import gql from "graphql-tag";
import GoogleAnalyticsHelper from "reactSrc/helpers/GoogleAnalyticsHelper";
import {gaFunction} from "reactSrc/helpers/gaFunctions.ts";
class AWSHelper {
  static client = new AWSHelper();
  appSyncClient = new AWSAppSyncClient({
    url: process.env.REACT_APP_APP_SYNC_URL,
    region: process.env.REACT_APP_AWS_REGION,
    auth: {
      type: AUTH_TYPE.API_KEY,
      apiKey: process.env.REACT_APP_APP_SYNC_API_KEY,
    },
  });
  cognitoUrl = process.env.REACT_APP_AWS_COGNITO_DOMAIN_URL;
  federatedConfig = {
    facebook_app_id: process.env.REACT_APP_FACEBOOK_APP_ID,
    google_client_id: process.env.REACT_APP_GOOGLE_APP_ID,
    amazon_client_id: process.env.REACT_APP_AMAZON_APP_ID,
  };

  getConfigureObj() {
    return Auth.configure();
  }

  static getFederatedConfig() {
    return this.client.federatedConfig;
  }

  static async appSyncQuery(query) {
    try {
      const response = await this.client.appSyncClient.query({
        query: gql(query),
        fetchPolicy: "network-only",
      });
      return response;
    } catch (e) {
      return e;
    }
  }

  static async appSyncMutation(mutation) {
    try {
      const response = await this.client.appSyncClient.mutate({
        mutation: gql(mutation),
      });
      return response;
    } catch (e) {
      return e;
    }
  }

  static async getCurrentUser() {
    try {
      const res = await Auth.currentAuthenticatedUser();
      return res;
    } catch (e) {
      return e;
    }
  }

  static async signIn(provider) {
    try {
      await Auth.federatedSignIn({ provider });
      GoogleAnalyticsHelper.sendEvent("Menu", "Sign in Type", provider);

      if ( gaFunction ){
        gaFunction.dsSendStandardEvent("Menu", "Sign in Type", provider);
      } 

    } catch (e) {
      return e;
    }
  }

  static async signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  static configureCognito() {
    const path = window.location.pathname.split("&")[0];

    Amplify.configure({
      aws_appsync_graphqlEndpoint: process.env.REACT_APP_APP_SYNC_URL,
      aws_appsync_region: process.env.REACT_APP_AWS_REGION,
      aws_appsync_authenticationType: "AWS_IAM",
      Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,

        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_AWS_REGION,

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: "us-west-1",

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_APP_ID,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        // cookieStorage: {
        //     // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //     domain: '.yourdomain.com',
        //     // OPTIONAL - Cookie path
        //     path: '/',
        //     // OPTIONAL - Cookie expiration in days
        //     expires: 365,
        //     // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        //     sameSite: "strict" | "lax",
        //     // OPTIONAL - Cookie secure flag
        //     // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        //     secure: true
        // },

        // OPTIONAL - customized storage object
        // storage: MyStorage,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        // authenticationFlowType: 'USER_PASSWORD_AUTH',

        // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        // clientMetadata: { myCustomKey: 'myCustomValue' },

        // OPTIONAL - Hosted UI configuration
        //?client_id=78p6l7gv7qtmf9dms3lbr9slua&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://dev.focus360.com
        oauth: {
          domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN_URL,
          scope: [
            "phone",
            "email",
            "profile",
            "openid",
            "aws.cognito.signin.user.admin",
          ],
          redirectSignIn: `${process.env.REACT_APP_DOMAIN}${path}`,
          redirectSignOut:
            path === "/"
              ? process.env.REACT_APP_DOMAIN
              : process.env.REACT_APP_DOMAIN + path,
          responseType: "token", // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
        federationTarget: "COGNITO_USER_POOLS",
      },
      Storage: {
        bucket: "focus360designer",
        region: process.env.REACT_APP_AWS_REGION,
      },
    });
    Auth.configure(this.client.getConfigureObj().oauth);
  }

  static async storePresetImage(communityID, presetHash, compositorcanvas) {
    let blobData = await new Promise(function (resolve) {
      compositorcanvas.toBlob(resolve);
    });

    let result = await Storage.put(
      "assets/" + communityID + "/designer/800/" + presetHash + ".jpg",
      blobData,
      {
        contentType: "image/jpeg",
      }
    );

    return result;
  }
}

export default AWSHelper;
