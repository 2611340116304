export const getCommunityCollection = function (collectionID) {
  return `
  query getCommunityCollection {
  listDesignerSceneCollections(filter: {id: {eq: "${collectionID}"}}) {
    items {
	  clientIDNumber
	  clientDivision
	  clientFont
	  clientLogoPath
    collectionID
	  communityID
	  communityName
	  collectionTagline
	  collectionTitle
    disclaimer
	  collectionBackgroundPathToImage
      filters {
        enabled
        label
        uid
        values {
          uid
          value
        }
      }
      id
      projectLogo
	  enableTutorial
      scenes {
        communityID
        enabled
        id
        label
        navigateToCollection
        collectionUrl
        extraJSON
        presets {
          pathToImages
          presetID
          presetLabel
        }
		relatedCollectionID
		collectionDropdownLabel
      }
    }
  }
}
 `;
};

export const getSceneData = function (id, sceneId) {
  return `
query GetSceneData {
  getDesignerScene(id: "${id}", sceneID: "${sceneId}") {
    appOptionHierarchy {
      cameraID
      optionRollover
      geoTypes {
        enabled
        geoTypeID
        geoTypeLabel
        includeLabelInInfo
        materials {
          enabled
          filters {
            filterUID
            valueUID
          }
          materialID
          menuOrder
          price
          productID
          swatchLabel
        }
        menuOrder
      }
      layer
      menuOrder
      optionID
      optionIcon
      optionLabel
	  optionRollover
    }
    cameraDynamicSwitching
    cameras {
      cameraID
      cameraIcon
      cameraLabel
      centerX
      centerY
	  printIndex
    }
    communityID
    filters {
      label
      uid
      values {
        uid
        value
      }
    }
    id
    menuOpacity
    showOptionsNavigationBar
	  planCode
    presets
    sceneID
    sceneSubTitle
    sceneTitle
    clientIDNumber
	clientDivision
	clientLogoPath
	clientFont
  originalURL
  }
}
`;
};

export const saveUserScene = function (sceneData) {
  return `
   mutation saveUserScene {
       createDesignerUserSaves(input:
    {
      clientIDNumber: "${sceneData.clientID}",
	  collectionID: "${sceneData.collectionID}",
      communityID: "${sceneData.communityID}",
      createdAt: "",
      id: "",
      presetID: "",
      pathToImages: "${sceneData.image}",
      presetLabel: "${sceneData.presetLabel}",
      selections: "${sceneData.selections}"
      sceneID: "${sceneData.sceneID}",
      sceneSubTitle: "${sceneData.sceneSubTitle}",
      sceneTitle: "${sceneData.sceneTitle}",
      userID: "${sceneData.userID}"
    })
     {
    clientIDNumber
	collectionID
    communityID
    createdAt
    id
    pathToImages
    presetID
    presetLabel
    selections   
    sceneID
    sceneSubTitle
    sceneTitle
    userID
  }
 }
 `;
};

export const getUserSaves = function (userID, communityID, sceneID) {
  return `
   query getUserSaves {
  listDesignerUserSaves(filter: {userID: {eq: "${userID}"}, communityID: {eq: "${communityID}"}}) {
    items {
      clientIDNumber
	  collectionID
      communityID
      id
      userID
      pathToImages
      presetID
      presetLabel
      selections
      sceneID
      sceneSubTitle
      sceneTitle
    }
  }
}
 `;
};

export const getUserPreset = function (id, userID) {
  return `
  query getUserPreset {
  getDesignerUserSaves(id: "${id}", userID: "${userID}") {
    id
    clientIDNumber
	collectionID
    communityID
    pathToImages
    presetID
    presetLabel
    sceneID
    sceneSubTitle
    sceneTitle
    selections
    userID
  }
}
 `;
};

export const getUserFavoriteMaterials = function (userID, communityID) {
  return `
  query getUserFavoriteMaterials {
  listDesignerUserFavoriteMaterials(filter: 
    {
      userID: {eq: "${userID}"}, 
      communityID: {eq: "${communityID}"}
    })
    {
    items {
      communityID
      id
      materialID
      userID
    }
  }
}
 `;
};

export const deleteUserSave = function (id, userID) {
  return `
 mutation deleteUserSave {
  deleteDesignerUserSaves(input: 
    {
      id: "${id}", 
      userID: "${userID}"
    }) {
    clientIDNumber
	collectionID
    communityID
    id
    pathToImages
    presetID
    presetLabel
    selections
    sceneID
    sceneSubTitle
    sceneTitle
    userID
  }
}
 `;
};

export const favoriteUserMaterial = function (communityID, materialID, userID) {
  return `
   mutation userFavoriteMaterial {
  createDesignerUserFavoriteMaterials(
    input:
    {
      communityID: "${communityID}",
      id: "",
      materialID: "${materialID}",
      userID: "${userID}"
    })
  {
    communityID
    id
    materialID
    userID
  }
}
 `;
};

export const deleteFavoriteUserMaterial = function (id, userID) {
  return `
  mutation deleteFavoriteUserMaterial {
  deleteDesignerUserFavoriteMaterials(input:
    {
      id: "${id}",
      userID: "${userID}"
    })
    {
      id
    }  
}
 `;
};

export const trackUserSelections = function (
  userID,
  email = "",
  communityID = "",
  sceneID = "",
  clientIDNumber = "",
  clientDivision = "",
  communityName = "",
  username = "",
  name = ""
) {
  return `
 mutation trackUserSelections {
  createUserAccessTracking(input: {
    appID: "NEXT Designer", 
    communityID: "${communityID}", 
	communityName: "${communityName}",
	clientIDNumber: "${clientIDNumber}",
	clientDivision: "${clientDivision}",
    sceneID: "${sceneID}", 
    timestamp: "", 
    userID: "${userID}",
	username: "${username}",
	name: "${name}",
	email: "${email}"
  }) {
    appID
    communityID
    sceneID
    timestamp
    userID
	username
	name
	email
  }
}
 `;
};

export const getUserTrackingReport = function (
  clientIDNumber = "",
  clientDivision = ""
) {
  return `
  query getUserTrackingReport {
  listUserAccessTrackings(filter: {clientIDNumber: {eq: "${clientIDNumber}"}, clientDivision: {eq: "${clientDivision}"}}) {
    items {
	  clientIDNumber
	  clientDivision
      appID
      communityID
      communityName
      email
      name
      sceneID
      timestamp
      userID
    }
  }
}
`;
};

export const setUserTermsAcceptance = function (userID) {
  return `
mutation setUserTermsAcceptance {
  createUserTermsAcceptance(input: {
	acceptedTerms: true, 
	accessTimestamp: "", 
	appID: "NEXT Designer", 
	userID: "${userID}"
  }) {
    acceptedTerms
    accessTimestamp
    appID
    userID
  }
}
`;
};

export const getUserTermsAcceptance = function (userID) {
  return `
query getUserTermsAcceptance {
  getUserTermsAcceptance(appID: "NEXT Designer", userID: "${userID}") {
    acceptedTerms
    accessTimestamp
    appID
    userID
  }
}
`;
};
