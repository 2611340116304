import { v4 as uuidv4 } from "uuid";
import circularJSON from "circular-json";
import ismobile from "is-mobile";
import moment from "moment";

class GeneralHelper {
  static currencyFormatter(x) {
    if (!x) {
      return "";
    }
    if (typeof x === "number") {
      x = x.toString();
    }
    return "$" + x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  static iterateKey(obj) {
    if (obj.hasOwnProperty("iterateKey")) return obj.iterateKey;

    obj.iterateKey = uuidv4();

    return obj.iterateKey;
  }

  // static deleteNotifications(type) {
  //     this.referralAxios(
  //         axiosConfig("delete-notifications", { type: type })
  //     ).catch(() => false);
  // }

  static capitalizeFirstLetterEachWord(string) {
    return string
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  }

  static showQuota(authUser) {
    return authUser.quota_type !== "unlimited";
  }

  static vForKey(obj) {
    if (obj.hasOwnProperty("vForKey")) return obj.vForKey;

    obj.vForKey = uuidv4();

    return obj.vForKey;
  }

  static isObjectEmpty(obj) {
    return obj ? !Object.keys(obj).length : true;
  }

  static truncatedReferralTitle(title) {
    return title.substring(0, 21) + "...";
  }

  static channelAvatar(channel) {
    return channel.photo ? channel.photo : "/images/i-blankImg.svg";
  }

  static channelName(channel) {
    return !channel.chat_members.length
      ? ""
      : JSON.parse(atob(channel.chat_members[0].identity)).fullName;
  }

  static currency(value) {
    if (value === null) return "";

    value = typeof value === "string" ? parseFloat(value) : value;

    if (value >= 1000000000)
      return (value / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
    else if (value >= 1000000)
      return (value / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    else if (value >= 1000)
      return (value / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    else return value.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  }

  static dateFormat(date) {
    if (!date) return "";

    const [year, month, day] = date.split(" ")[0].split("-");
    return `${month}/${day}/${year}`;
  }

  static formatChatDate(d) {
    if (d !== undefined && d !== null) {
      d = d.toString().split(" ", 4);
      const [day, month, date, year] = d;
      return `${day} ${month} ${date}th`;
    }
  }

  static queryParamsToObject(location) {
    let search = location.search.substring(1);

    if (search === "" || typeof search !== "string") {
      return {};
    }
    return JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
  }

  static getUserPhoto(user) {
    return user.photo !== null ? user.photo : "/images/i-blankImg.svg";
  }

  static getPlanIconFromGroupId(groupId) {
    if (groupId === 1) return "/images/core-logo.svg";
    else if (groupId === 2) return "/images/elite-logo.svg";
    else if (groupId === 3) return "/images/team-logo.svg";
    else if (groupId === 5) return "/images/coach-logo.svg";
    else if (groupId === 7) return "/images/mgmt-logo.svg";
    else if (groupId === 8) return "/images/pro-logo.svg";
    else if (groupId === 9) return "/images/community-logo.svg";
    else if (groupId === 10) return "/images/lite-logo.svg";
  }

  static removeCommas(numberString) {
    if (numberString !== null) {
      numberString = numberString.replace(/,\s?/g, "");
      return parseInt(numberString);
    } else {
      return 0;
    }
  }

  static addCommas(number) {
    if (number !== undefined && number !== null) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  static getUniqueKey() {
    return uuidv4();
  }

  static generateStrFromObj(str) {
    return `${Object.keys(str)
      .map((key) => key + "=" + str[key])
      .join("&")}`;
  }

  static checkReceivedProps(props, nextProps) {
    if (circularJSON.stringify(props) !== circularJSON.stringify(nextProps)) {
      return true;
    }
    return false;
  }

  static isNumeric(val) {
    return !isNaN(val);
  }

  static getFileNameFromFullUrl(url) {
    if (!url) return "";

    var m = url.toString().match(/.*\/(.+?)\./);
    if (m && m.length > 1) {
      return m[1];
    }

    return "";
  }

  static getFileTypeFromS3Url(url) {
    let type = url.split("?")[0].split(".").pop();
    return type;
  }

  // static getFileIcon(url) {
  //     const ext = this.getFileTypeFromS3Url(url);
  //
  //     switch (ext) {
  //         case 'pdf':  return <img src={PDFIcon} alt={'pdf'} style={{height: '60px'}}/>;
  //         case 'mp4':  return <img src={MP4VideoIcon} alt={'mp4'} style={{height: '60px'}}/>;
  //         case 'mp3':  return <img src={MP4AudioIcon} alt={'mp3'} style={{height: '60px'}}/>;
  //         case 'docx': return <img src={DocxIcon} alt={'docx'} style={{height: '60px'}}/>;
  //         case 'jpg':  return <img src={JPGIcon} alt={'pdf'} style={{height: '60px'}}/>;
  //         case 'jpeg': return <img src={JPGIcon} alt={'pdf'} style={{height: '60px'}}/>;
  //         case 'svg': return <img src={SVGIcon} alt={'pdf'} style={{height: '60px'}}/>;
  //         case 'png': return <img src={PNGIcon} alt={'pdf'} style={{height: '60px'}}/>;
  //         default: return <img src={MiscIcon} alt={'pdf'} style={{height: '60px'}}/>;
  //     }
  // }

  static getCurrentYear() {
    return new Date().getFullYear();
  }

  static camelCaseString = (string) => {
    return string
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "")
      .replace(/\//g, "");
  };

  static getSum = (array) => {
    let total = 0;
    array.forEach((value) => {
      if (value) {
        total += parseFloat(value);
      }
    });
    return total;
  };

  static formatMoney = (amount) => {
    if (amount !== null && amount !== undefined) {
      amount = typeof amount === "string" ? amount : amount.toString();
      amount = amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return "$" + amount;
    } else {
      return "$0";
    }
  };

  static numberWithCommas(number, emptyType = "string") {
    if (number) {
      let float = parseFloat(number);
      if (float && float % 1 !== 0) {
        number = parseFloat(number).toFixed(2);
      }
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    if (emptyType === "string") {
      return "";
    } else {
      return 0;
    }
  }

  static validateEmail(email) {
    let emailTest = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return emailTest.test(email);
  }

  static getYearFromQueryParam(location) {
    if (location) {
      const search = location.search;
      const params = new URLSearchParams(search);
      let goalYear = params.get("year") ? parseInt(params.get("year")) : 2020;
      return goalYear < 2020 ? 2020 : goalYear;
    }
  }

  static getFromUrlQuery(location, key) {
    if (location) {
      const search = location.search;
      const params = new URLSearchParams(search);
      return params.get(key) ? params.get(key) : null;
    }
    return null;
  }

  static expandFullScreen() {
    let appElem = document.getElementById("root");

    if (appElem.requestFullscreen) {
      appElem.requestFullscreen();
    } else if (appElem.webkitRequestFullscreen) {
      appElem.webkitRequestFullscreen();
    } else if (appElem.msRequestFullscreen) {
      appElem.msRequestFullscreen();
    }
  }

  static setItemToLocalStorage(key, value) {
    if ( value || value === false ) {
      if ( ( typeof value === 'object' ) || ( typeof value === 'array' ) ) {
        localStorage.setItem(key, JSON.stringify(value));
      } else {
        localStorage.setItem(key, value);
      }
    }
  }

  static setExpireTimeToLocalStorage(key, min) {
    const current = new Date();
    const timeFromNow = moment(current).add(min, "m").toDate();
    this.setItemToLocalStorage(key, timeFromNow);
  }

  static retrieveLocalStorageItem(key) {
    const item = localStorage.getItem(key);
    try {
      return JSON.parse(item);
    } catch (e) {
      return item;
    }
  }

  static loadUrlParams(location) {
    const params = this.queryParamsToObject(location);
    const localParams = this.retrieveLocalStorageItem("params");
    if (location.pathname === "/") {
      if (params.communityID) {
        return params;
      } else if (localParams && localParams.communityID) {
        return localParams;
      }
    } else {
      if (params.communityID && params.sceneID) {
        return params;
      } else if (
        localParams &&
        localParams.communityID &&
        localParams.sceneID
      ) {
        return localParams;
      }
    }
  }

  static detectMobileOrientation() {
    return !ismobile()
      ? "desktop"
      : window.innerHeight > window.innerWidth
      ? "portrait"
      : "landscape";
  }

  static detectIOSChrome() {
    let userAgent = window.navigator.userAgent;

    return !userAgent.match(/CriOS/i) ? false : true;
  }

  static downloadJSON2CSV(items) {
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys({ "#": "", ...items[0] });
    const csv = [
      header.join(","), // header row first
      ...items.map((row, index) =>
        header
          .map((fieldName) =>
            JSON.stringify(
              fieldName === "#" ? index + 1 : row[fieldName],
              replacer
            )
          )
          .join(",")
      ),
    ].join("\r\n");

    let blob = new Blob([csv]);

    let a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
    a.download = "DesignerUserReport.csv";
    document.body.appendChild(a);
    a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
    document.body.removeChild(a);
  }
}

export default GeneralHelper;
