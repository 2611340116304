class GoogleAnalyticsHelper {
  static client = new GoogleAnalyticsHelper();
  hitString = "https://www.google-analytics.com/collect?v=1&tid=";
  sendQueueItems = [];

  initBasicTracking(GAproperty) {
    this.hitString += GAproperty;
    this.hitString += "&cid=" + this.uuidv4();
    this.sendQueueItems = [];
    this.trackerIsActive = true;

    setInterval(() => {
      this.onTimerCheckQue();
    }, 250);
  }

  initClientTracking(
    GAPropertyID,
    focusClientID,
    builderName,
    builderDivision,
    projectName,
    CommunityID,
    sourceName
  ) {
    let ClientUDID = this.uuidv4();
    //where is GAproperty coming from?
    this.hitString += GAPropertyID;
    this.hitString += encodeURI(
      "&cid=" +
        ClientUDID +
        "&cd1=" +
        focusClientID +
        "&cd2=" +
        builderName +
        "&cd3=" +
        builderDivision +
        "&cd4=" +
        projectName +
        "&cd5=" +
        CommunityID +
        "&cd7=" +
        sourceName
    );
    this.sendQueueItems = [];
    setInterval(() => {
      this.onTimerCheckQue();
    }, 250);
  }

  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  onTimerCheckQue() {
    if (this.sendQueueItems.length > 0) {
      if ( this.trackerIsActive ) {
        fetch(this.hitString + this.sendQueueItems[0])
            .then((response) => {
              if (response.status.toString() !== "200") {
                //console.log(response);
              }
            })
            .catch((error) => {
              /* if google-analytics fails, likly ad blocker, attempt proxy url */
              this.hitString = this.hitString.replace(
                  "www.google-analytics.com",
                  "analytics.focus360.com"
              );
            });
      }
      //console.log(this.sendQueueItems[0]);
      this.sendQueueItems.shift();
    }
  }

  static setLoggedInUserID(userID) {
    this.addCustomDimension(9, userID);
  }

  static setUserSub( userID ) {
    this.addCustomDimension(10, userID);
  }

  static disableTracking(){
    this.client.trackerIsActive = false;
  }

  static enableTracking(){
    this.client.trackerIsActive = true;
  }

  static addCustomDimension(index, value) {
    let cdStr = "&cd" + index.toString();

    /* if index is found in current string remove */
    if (this.client.hitString.indexOf(cdStr) !== -1) {
      let spHitStr = this.client.hitString.split("&");
      let spIndex = -1;
      let fStr = "cd" + index.toString() + "=";
      for (let i = 0; i < spHitStr.length; ++i) {
        if (spHitStr[i].indexOf(fStr) === 0) {
          spIndex = i;
        }
      }
      if (spIndex !== -1) {
        spHitStr.splice(spIndex, 1);
      }
      this.client.hitString = spHitStr.join("&");
    }

    this.client.hitString += encodeURI(cdStr + "=" + value);
  }

  static startSession() {
    this.client.sendQueueItems.push(
      "&t=event&ec=App&ea=Start%20Session&sc=start"
    );
  }

  static endSession() {
    this.client.sendQueueItems.push("&t=event&ec=App&ea=End%20Session&&sc=end");
  }

  static sendPageview(pageURL) {
    this.client.sendQueueItems.push("&t=pageview&dl=" + pageURL);
  }

  static sendEvent(eventCategory, eventAction, eventLabel, eventValue = 0) {
    this.client.sendQueueItems.push(
      encodeURI(
        "&t=event&ec=" +
          eventCategory +
          "&ea=" +
          eventAction +
          "&el=" +
          eventLabel +
          "&ev=" +
          eventValue
      )
    );
  }

  static sendTiming(timeCategory, timeAction, timeLabel, timeValue = 0) {
    this.client.sendQueueItems.push(
      encodeURI(
        "&t=timing&utc=" +
          timeCategory +
          "&utv=" +
          timeAction +
          "&utl=" +
          timeLabel +
          "&utt=" +
          timeValue
      )
    );
  }
}

export default GoogleAnalyticsHelper;
