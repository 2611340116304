const initialState = {
  appOptionHierarchy: {},
  scene: {},
  list: [],
};

function preset(state = initialState, action) {
  switch (action.type) {
    case "SET_PRESET":
      return {
        ...state,
        scene: action.payload,
      };
    case "SET_PRESET_LIST":
      return {
        ...state,
        list: action.payload,
      };
    case "SET_APP_HIERARCHY":
      return {
        ...state,
        appOptionHierarchy: action.payload,
      };
    default:
      return state;
  }
}

export default preset;
