import React, { useEffect, useState } from "react";
import {
  getCommunityCollection,
  getSceneData,
} from "reactSrc/constants/gql/query/appSyncQuery";
import "./App.css";
import ReactDOM from "react-dom";
import "./index.css";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import store from "reactSrc/store";
import Layout from "reactSrc/components/layout/Layout";
import awsHelper from "reactSrc/helpers/AWSHelper";
import GeneralHelper from "reactSrc/helpers/GeneralHelper";

function App() {
  const [theme, setTheme] = useState({
    typography: {
      useNextVariants: true,
      fontSize: 15,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    palette: {
      primary: { main: "#006DB7" },
      secondary: { main: "#173159" },
    },
    overrides: {
      MuiPaper: {
        root: {
          textTransform: "uppercase",
        },
      },
      MuiBreadcrumbs: {
        root: {
          textTransform: "uppercase",
        },
      },
      MuiTypography: {
        body1: {
          fontSize: "calc( 10px + 0.3vw)",
          zIndex: 1000,
        },
        body2: {
          fontSize: "calc( 10px + 0.3vw)",
          zIndex: 1000,
        },
        h5: {
          fontSize: "calc( 16px + 0.3vw)",
          fontWeight: 600,
          zIndex: 1000,
        },
        h6: {
          fontSize: "calc( 20px + 0.3vw)",
          fontWeight: 600,
          zIndex: 1000,
        },
        caption: {
          fontSize: "calc( 8px + 0.3vw)",
          zIndex: 1000,
        },
      },
    },
  });

  useEffect(() => {
    getBuilderTheme();
  }, []);

  const getBuilderTheme = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const localParams = GeneralHelper.retrieveLocalStorageItem("params");

    if (urlParams.has("collectionID")) {
      const {
        data: {
          listDesignerSceneCollections: { items },
        },
      } = await awsHelper.appSyncQuery(
        getCommunityCollection(urlParams.get("collectionID"))
      );

      if (items.length) {
        console.log( items );
        let builderTheme = {
          ...theme,
          typography: {
            ...theme.typography,
            fontFamily: items[0].clientFont
              ? items[0].clientFont
              : '"Roboto", "Helvetica", "Arial", sans-serif',
          },
        };

        setTheme(builderTheme);
      }
    } else if (localParams && localParams.collectionID) {
      const {
        data: {
          listDesignerSceneCollections: { items },
        },
      } = await awsHelper.appSyncQuery(
        getCommunityCollection(localParams.collectionID)
      );

      if (items.length) {
        let builderTheme = {
          ...theme,
          typography: {
            ...theme.typography,
            fontFamily: items[0].clientFont
              ? items[0].clientFont
              : '"Roboto", "Helvetica", "Arial", sans-serif',
          },
        };

        setTheme(builderTheme);
      }
    } else if (urlParams.has("communityID") && urlParams.has("sceneID")) {
      let {
        data: { getDesignerScene },
      } = await awsHelper.appSyncQuery(
        getSceneData(
          `${urlParams.get("communityID")}-${urlParams.get("sceneID")}`,
          urlParams.get("sceneID")
        )
      );
      let item = getDesignerScene;

      let builderTheme = {
        ...theme,
        typography: {
          ...theme.typography,
          fontFamily: item.clientFont
            ? item.clientFont
            : '"Roboto", "Helvetica", "Arial", sans-serif',
        },
      };

      setTheme(builderTheme);
    } else if (localParams.communityID && localParams.sceneID) {
      let {
        data: { getDesignerScene },
      } = await awsHelper.appSyncQuery(
        getSceneData(
          `${localParams.communityID}-${localParams.sceneID}`,
          localParams.sceneID
        )
      );
      let item = getDesignerScene;

      let builderTheme = {
        ...theme,
        typography: {
          ...theme.typography,
          fontFamily: item.clientFont
            ? item.clientFont
            : '"Roboto", "Helvetica", "Arial", sans-serif',
        },
      };

      setTheme(builderTheme);
    }
  };

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={createTheme(theme)}>
        <Layout />
      </MuiThemeProvider>
    </Provider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
