import Loadable from "react-loadable";
import LoadingComponent from "reactSrc/components/common/loader";

const ROUTES = [
  {
    path: "/",
    exact: true,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "js/selector" */ "reactSrc/components/pages/selector/SelectorPage.js"
        ),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/scene",
    exact: true,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "js/home" */ "reactSrc/components/pages/home/HomePage.js"
        ),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/signin",
    exact: true,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "js/signin" */ "reactSrc/components/pages/signin/SignInPage.js"
        ),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/404",
    exact: true,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "js/signin" */ "reactSrc/components/pages/404"
        ),
      loading: LoadingComponent,
    }),
  },
];

export default ROUTES;
